import axios from "axios";
import React, { useEffect } from "react";
import Head from "next/head";
import { Layout } from "antd";
import "antd/dist/antd.css";
import S from "styled-components";
import HeaderContent from "./headerContent";
import FooterContent from "./footerContent";
import theme from "../components/Theme";
import { AppContext } from "../context/appContextProvider";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { setUserLocation } from "../redux/actions/appActions";

const { Header, Footer, Content } = Layout;

const StyledLayout = S(Layout)`
	min-height: 100vh;
`;
const AppHeader = S(Header)`
	height:100px;
	background: transparent;
    line-height: 1;
`;
const AppContent = S(Content)`
	background-color: ${theme.colors.white};
	display: flex;
	flex-direction: column;
	align-items: center;
`;
const AppFooter = S(Footer)`
`;

const AppLayout = ({ children, sessionData }) => {
    const Router = useRouter();
    const dispatch = useDispatch();

    const [reRender] = React.useState(0);
    const appContext = React.useContext(AppContext);
    const token = appContext.getSessionToken();
    const sessionLocation = appContext.getSessionLocation();
    const { setLoggedInUserDataFunc } = appContext;

    useEffect(() => {
        initUserLocation();
    }, [reRender]);

    const initUserLocation = async () => {
        let selectedUserLocation =
            localStorage.getItem("userLocation") || undefined;

        if (!selectedUserLocation) {
            const locationResponse = await axios.get(
                process.env.NEXT_APP_LOCATION_DETECTOR
            );
            const { data } = locationResponse;
            selectedUserLocation = data.location.country.code;
        }

        if (Router.asPath !== "/user/sign-out") {
            if (token) {
                if (sessionData && sessionLocation === selectedUserLocation) {
                    setLoggedInUserDataFunc(sessionData);
                } else {
                    Router.replace("/user/sign-out");
                }
            }
        }
        dispatch(
            setUserLocation({
                userLocation: selectedUserLocation,
                isUserLocationCustom: false,
            })
        );
    };

    return (
        <StyledLayout>
            <Head>
                <title>Prescribe Online</title>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, user-scalable=no"
                />
                <link rel="icon" href="/images/favicon.png" />
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link
                    rel="preconnect"
                    href="https://fonts.gstatic.com"
                    crossOrigin={"true"}
                />
                <link
                    href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap"
                    rel="stylesheet"
                />

                {/*Google Tag Manager*/}

                <script
                    dangerouslySetInnerHTML={{
                        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-W24KT2W');`,
                    }}></script>
                <script src="//unpkg.com/@ungap/global-this"></script>
                {/*End Google Tag Manager*/}
            </Head>
            <body>
                <noscript>
                    <iframe
                        src="https://www.googletagmanager.com/ns.html?id=GTM-W24KT2W"
                        height="0"
                        width="0"
                        style={{ display: "none", visibility: "hidden" }}
                    />
                </noscript>

                <AppHeader>
                    <HeaderContent />
                </AppHeader>
                <AppContent>{children}</AppContent>
                <AppFooter>
                    <FooterContent />
                </AppFooter>
            </body>
        </StyledLayout>
    );
};

// const mapStateToProps = (state) => {
//     return {
//         userLocation: state.appReducer.userLocation,
//     };
// };

// const mapDispatchToProps = {};

// export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);

export default AppLayout;
