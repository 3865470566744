import React from "react";
import PropTypes from "prop-types";
import { useCookies } from "react-cookie";

const moment = require("moment-timezone");

const AppContext = React.createContext({});

const AppContextProvider = ({ children }) => {
    const [cookies, setCookie, removeCookie] = useCookies([
        "poSessionToken",
        "poSessionLocation",
    ]);
    const [loggedInUserData, setLoggedInUserData] = React.useState(undefined);

    const setSessionToken = (sessionToken, sessionExpiry, userLocation) => {
        const now = moment(new Date());
        const expiry = moment
            .utc(
                moment(sessionExpiry, "YYYY-MM-DD HH:mm:ss").format(
                    "YYYY-MM-DD HH:mm:ss"
                )
            )
            .tz(moment.tz.guess());
        const expirySeconds = expiry.diff(now, "minutes") * 60;
        setCookie("poSessionToken", sessionToken, {
            path: "/",
            maxAge: expirySeconds,
        });
        setCookie("poSessionLocation", userLocation, {
            path: "/",
            maxAge: expirySeconds,
        });
    };

    const getSessionToken = () => {
        const sessionToken = cookies.poSessionToken || undefined;
        return sessionToken;
    };

    const getSessionLocation = () => {
        const sessionLocation = cookies.poSessionLocation || undefined;
        return sessionLocation;
    };

    const logout = () => {
        removeCookie("poSessionToken", { path: "/" });
        removeCookie("poSessionLocation", { path: "/" });
        setLoggedInUserData(undefined);
    };

    const setLoggedInUserDataFunc = (data) => setLoggedInUserData(data);

    return (
        <AppContext.Provider
            value={{
                values: {
                    loggedInUserData,
                },
                setSessionToken,
                getSessionToken,
                getSessionLocation,
                logout,
                setLoggedInUserDataFunc,
            }}>
            {children}
        </AppContext.Provider>
    );
};

AppContextProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export { AppContext };
export default AppContextProvider;
