import { ProductMock } from "../../../util/types";

export const products = [
    {
        id: "4",
        name: "Alesse",
        pageTitle:
            "Get Alesse Prescription Online - Family Planning Contraceptive ",
        slug: "alesse",
        ingrediant: "Progestin / Estrogen",
        // known: "Known as Loette,Miranova,Microgynon 20ED in the UK",
        description:
            "Alesse is a combination (progestin / estrogen) birth control medication that works to interrupt the ovulation process where an egg is released from the ovary, as well as promoting changes to cervical and uterine linings that prevent sperm from reaching the uterus or having a fertilized egg attach to it. It is a popular contraceptive medication that is available with a doctor’s prescription and recommended for effective family planning. ",
        pageDescription:
            "Alesse prescription medication is a hormonal contraceptive for women and families to aid family planning. Get Alesse prescription online at Prescribe Online.",
        bannerImage: "/images/Alesse.png",
        useImage: "/images/bc-use.png",
        category: "bc",
        directions:
            "The ideal time to start the Alesse birth control course is the first day (day 1) of your menstrual period. You can also start taking the pills the fifth day of your period as well. It is suggested that the pills must be consumed around the same time regularly, if possible, prior to the evening meal or at sleep time. You can consume the birth control pills with or without food.",
        cautions:
            "Use of Alesse birth control medication may not be suitable for women who are receiving hyperlipidemia treatments, have impaired liver function or are being treated for jaundice, or are cigarette smokers over the age of 35. Doctors may also suggest other non-pharmaceutical birth control methods for women who are suffering from severe clinical depression or other mental/ mood disorders ",
        sideeffects:
            "Alesse side effects can include mild nausea, vomiting, bloating, breast tenderness or swelling, freckles or darkening of facial skin, increased hair growth, scalp hair loss, changes in weight or appetite, problems with contact lenses, or vaginal itching or discharge. ",
        faq: [
            {
                question: "Is this authentic branded Alesse medication?",
                answer: "Yes, when you order medication online through Prescribe Online you’ll receive the same authentic and branded Alesse medication that you’d receive at any other pharmacy. But here you can get your prescription written online, filled by a licensed pharmacist, and have the genuine branded medication delivered to your home. ",
            },
            {
                question:
                    "Do you have generic Alesse birth control medication?",
                answer: "Yes, you can get a prescription here and order generic Alesse online.",
            },
            {
                question: "Am I able to get refills on my Alesse prescription?",
                answer: "Prescribe Online customers may receive a prescription for up to 12 months (1 year) of medication depending on being approved for it based on their health questionnaire. There may be restrictions on the quantity of medication that can be dispensed at one time. If your prescription for Alesse birth control medication indicates refills then you are able to get the prescription refilled as indicated up to 1 year. You will not need to have a follow-up consultation during that 1-year period. ",
            },
        ],
        uses: [
            "Use this birth control medication exactly as indicated in your prescription; take your first Alesse contraceptive pill on the first day of your period or on the first Sunday after your period begins.",
            "Take one pill every day, and make sure that you take each pill within 24 hours of the last one. It is best if you can take your Alesse at the same time every day. ",
            "After 21 days (3 weeks) of taking the medication, you will start taking the ‘reminder’ pills on day 22 and take one pill each day for 7 days. Your period should begin during this 4th week. ",
            "It is best to get your birth control medication prescription refilled online before you completely finish your medication. Alesse must be taken on consecutive 3-week / 1-week cycles to ensure pregnancy prevention. If you are not able to take your medication as directed you will need to use other contraception methods.",
        ],
    },
    {
        id: "5",
        name: "Ortho Tri-Cyclen Lo",
        pageTitle:
            "Get Ortho Tri-Cyclen Lo Prescription Online - Birth Control Meds ",
        slug: "ortho-tri-cyclen-lo",
        ingrediant: "Norgestimate / Ethinyl Estradiol",
        // known: "Known as Loette, Miranova, Microgynon 20ED in the UK",
        description:
            "Ortho Tri Cyclen Lo is combination hormonal birth control medication containing Norgestimate and Ethinyl Estradiol. It effectively prevents pregnancy by stopping the body from releasing an egg and interrupting the ovulation cycle. It also works to thicken vaginal fluid to stop sperm from reaching the egg as well as changing the uterus lining so that any fertilized egg would not be able to attach to the lining. This birth control medication is also popular because for some women it makes their period less painful.",
        pageDescription:
            "Ortho Tri Cyclen Lo prescription medication is combination birth control medication that prevent pregnancy. Get Ortho Tri Cyclen Lo prescription online today!",
        bannerImage: "/images/Ortho.png",
        useImage: "/images/ed-use.png",
        category: "bc",
        directions:
            "The ideal time to start the Alesse birth control course is the first day (day 1) of your menstrual period. You can also start taking the pills the fifth day of your period as well. It is suggested that the pills must be consumed around the same time regularly, if possible, prior to the evening meal or at sleep time. You can consume the birth control pills with or without food.",
        cautions:
            "Ortho Tri Cyclen may not be appropriate as birth control medication if a woman has any history of blood clots, high blood pressure, abnormal breast exams, endometrial or breast cancer, high blood cholesterol levels, diabetes, depression, angioedema, gallbladder problems, migraine headaches, heart problems like irregular heartbeat or heart attack, kidney or liver disease, stroke, edema, thyroid problems or unexplained vaginal bleeding.",
        sideeffects:
            "Possible side effects from using Ortho Tri Cyclen include nausea, vomiting, headache, bloating, breast tenderness, fluid retention causing swelling of the feet and / or ankles, and weight changes.",
        faq: [
            {
                question:
                    "Is this authentic branded Ortho Tri-Cyclen Lo Medication?",
                answer: "Yes, when you order medication online through Prescribe Online you’ll receive the same authentic and branded Ortho Tri-Cyclen Lo medication that you’d receive at any other pharmacy. But here you can get your prescription written online, filled by a licensed pharmacist, and have the genuine branded medication delivered to your home.  ",
            },
            {
                question:
                    "Do you have generic Ortho Tri-Cyclen Lo birth control medication?",
                answer: "Yes, you can get a prescription here and order generic Ortho Tri-Cycle online.",
            },
            {
                question:
                    "Am I able to get refills on my Ortho Tri-Cyclen Lo prescription?",
                answer: "Prescribe Online customers may receive a prescription for up to 12 months (1 year) of medication depending on being approved for it based on their health questionnaire. There may be restrictions on the quantity of medication that can be dispensed at one time. If your prescription for Ortho Tri-Cyclen Lo birth control medication indicates refills then you are able to get the prescription refilled as indicated up to 1 year. You will not need to have a follow-up consultation during that 1-year period.",
            },
        ],
        uses: [
            "Take one Ortho Tri-Cyclen pill once a day, and taking it at the same time each day is best",
            "This medication is taken on a 28-day cycle. ",
            "Start with first pill of 21 in the pack and taken them one after another.",
            "After day 21, you will take the 7 inactive ‘reminder’ pills once a day over the next week if they are provided with your prescription. If not, you will take no pills for the last 7 days of the 28 day cycle. Your period should begin during this 4th week. ",
        ],
    },

    {
        id: "6",
        name: "Sprintec",
        pageTitle:
            "Get Sprintec Prescription Online for Combination Birth Control",
        slug: "sprintec",
        ingrediant: "Norgestimate / Ethinyl Estradiol",
        // known: "Known as Loette, Miranova, Microgynon 20ED in the UK",
        description:
            "Sprintec is combination hormonal birth control medication that women take orally once a day on 28-day cycle to prevent pregnancy. It works to interrupt the ovulation cycle by preventing the ovary’s release of an egg, along with thickening vaginal fluid and changing the lining of the uterus wall.",
        pageDescription:
            "Sprintec is combination hormonal birth control medication women take daily on 28-day cycle to help and prevent pregnancy. Get Sprintec prescription online now.",
        bannerImage: "/images/Sprintec.png",
        useImage: "/images/ed-use.png",
        category: "bc",
        directions:
            "The ideal time to start the Alesse birth control course is the first day (day 1) of your menstrual period. You can also start taking the pills the fifth day of your period as well. It is suggested that the pills must be consumed around the same time regularly, if possible, prior to the evening meal or at sleep time. You can consume the birth control pills with or without food.",
        cautions:
            "Sprintec may not be suitable as birth control medication for women with a history of blood clots, high blood pressure, abnormal breast exams, endometrial or breast cancer, high blood cholesterol levels, diabetes, depression, angioedema, gallbladder problems, migraine headaches, heart problems like irregular heartbeat or heart attack, kidney or liver disease, stroke, edema, thyroid problems or unexplained vaginal bleeding.",
        sideeffects:
            "Possible side effects from Sprintec use include nausea, vomiting, headache, bloating, breast tenderness, fluid retention causing swelling of the feet and / or ankles, and weight changes.",
        faq: [
            {
                question: "What is Sprintec?",
                answer: "Sprintec is a combination birth control medication that works to change a woman’s reproductive hormone levels so that no egg is released from an ovary in the way it would if she was not using birth control medication. This medication also makes it impossible for sperm to reach any fertilized egg by thickening the vaginal fluid along with changing the lining of the uterine wall and preventing any fertilized egg from attaching to it. This combination approach makes Sprintec a very reliable birth control medication and for some women it also makes their menstrual cycle less painful.",
            },
            {
                question: "How do I use Sprintec? ",
                answer: "Take Sprintec exactly as indicated in your prescription. The standard usage guideline is as follows; take one of the 21 active pill in the pack each day for the first 3 weeks of the month. Then take one of the 7 inactive (reminder) pills each day for the last week of the month. You should get your period during the 4th week of the cycle while you are taking the inactive pills.",
            },
            {
                question:
                    "What are potential side effects associated with using Sprintec?",
                answer: "Some women will be more prone to side effects from using this particular birth control medication, and Sprintec side effects can include nausea, vomiting, headache, bloating, breast tenderness, fluid retention causing swelling of the feet and / or ankles, and drastic changes to your weight.",
            },
            {
                question: "Is this authentic branded Sprintec medication?",
                answer: "Yes, when your order medication online through Prescribe Online you’ll receive the same authentic and branded Sprintec medication that you’d receive at any other pharmacy. But here you can get your prescription written online, filled by a licensed pharmacist, and have the genuine branded medication delivered to your home.",
            },
            {
                question: "Do you have generic Sprintec?",
                answer: "Yes, you can get a prescription here and order generic Sprintec online.",
            },
            {
                question:
                    "Am I able to get refills on my Sprintec prescription?",
                answer: "Prescribe Online customers may receive a prescription for up to 12 months (1 year) of medication depending on being approved for it based on their health questionnaire. There may be restrictions on the quantity of medication that can be dispensed at one time. If your prescription for Sprintec birth control medication indicates refills then you are able to get the prescription refilled as indicated up to 1 year. You will not need to have a follow-up consultation during that 1-year period.",
            },
        ],
        uses: [
            "Sprintec birth control is taken orally once each day, and it is best to take this medication at around the same time each day.",
            "There are 21 active pills in each Sprintec medication pack, and it will have 7 inactive pills too. Take one active pill each day for 21 days, and then conclude the monthly schedule by taking one of the 7 inactive pills each day for the last 7 days of the 28-day (4 week) schedule. ",
            "In the event you miss a dose do not take a make-up dose, and instead only resume taking your birth control medication as you would the next day at the regular time.",
            " If you miss 2 or more doses in a row you should use a barrier contraceptive method when having sex until you are able to re-establish your medication cycle.",
        ],
    },

    {
        id: "7",
        name: "Yaz",
        pageTitle: "Get Yaz Prescription Online - Hormonal Birth Control",
        slug: "yaz",
        ingrediant: "Drospirenone / Ethinyl Estradiol",
        // known: "Known as Loette, Miranova, Microgynon 20ED in the UK",
        description:
            "Yaz is combination hormonal birth control medication that women take orally once a day on 24-day cycle to prevent pregnancy. Interruption of the ovulation cycle occurs when the ovary is prevented from releasing an egg, and this medication also promotes thickening of vaginal fluid and changes to the lining of the uterus wall. ",
        pageDescription:
            "Yaz prescription medication is birth control designed to be taken during a woman's 24-day cycle which will prevent pregnancy. Get Yaz prescription online today.",
        bannerImage: "/images/Yaz.png",
        useImage: "/images/ed-use.png",
        category: "bc",
        directions:
            "The ideal time to start the Alesse birth control course is the first day (day 1) of your menstrual period. You can also start taking the pills the fifth day of your period as well. It is suggested that the pills must be consumed around the same time regularly, if possible, prior to the evening meal or at sleep time. You can consume the birth control pills with or without food.",
        cautions:
            "Women may not be approved to use Yaz if they have a history of blood clots, high blood pressure, abnormal breast exams, endometrial or breast cancer, high blood cholesterol levels, diabetes, depression, angioedema, gallbladder problems, migraine headaches, heart problems like irregular heartbeat or heart attack, kidney or liver disease, , edema, or gallbladder problems. ",
        sideeffects:
            "Using Yaz may cause side effects that include nausea, vomiting, headache, bloating, breast tenderness, fluid retention causing swelling of the feet and / or ankles, and weight changes.",
        faq: [
            {
                question: "What is Yaz?",
                answer: "Yaz is a combination birth control medication that affects changes with a woman’s reproductive hormone levels to prevent an egg being released from an ovary in the way it would if she was not using birth control medication. This medication also makes it impossible for sperm to reach any fertilized egg by thickening cervical mucus, plus changing the lining of the uterine wall and preventing any fertilized egg from attaching to it. This combination approach makes Yaz a very reliable birth control medication and some women find this medication also makes for a less painful menstrual cycle.",
            },
            {
                question: "How do I use Yaz? ",
                answer: "Take Yaz exactly as indicated in your prescription. The standard usage guideline is the woman taking one of the 24 active pill in the pack each day for the first 3 weeks and 3 days of the month. Then take one of the 4 inactive (reminder) pills each day for the last 4 days of the last week of the month. The woman should get her period during the 4th week of the cycle while she is taking the inactive pills",
            },
            {
                question:
                    "What are potential side effects associated with using Yaz?",
                answer: "Some women will be more prone to side effects from using this particular birth control medication, and Yaz side effects can include nausea, vomiting, headache, bloating. breast tenderness, fluid retention, weight change, vaginal bleeding between periods, irregular periods or high blood pressure.",
            },
            {
                question: "Is this authentic branded Yaz medication?",
                answer: "Yes, when your order medication online through Prescribe Online you’ll receive the same authentic and branded Yaz medication that you’d receive at any other pharmacy. But here you can get your prescription written online, filled by a licensed pharmacist, and have the genuine branded medication delivered to your home. ",
            },
            {
                question: "Do you have generic Yaz?",
                answer: "Yes, you can get a prescription here and order generic Yaz online.",
            },
            {
                question: "Am I able to get refills on my Yaz prescription?",
                answer: "Prescribe Online customers may receive a prescription for up to 12 months (1 year) of medication depending on being approved for it based on their health questionnaire. There may be restrictions on the quantity of medication that can be dispensed at one time. If your prescription for Yaz medication indicates refills then you are able to get the prescription refilled as indicated up to 1 year. You will not need to have a follow-up consultation during that 1-year period. ",
            },
        ],
        uses: [
            "Yaz birth control is taken orally once each day, and it is best to take this medication at around the same time each day. There are 24 active pills in each Yaz medication pack, and it will have 4 inactive pills too. ",
            "Take one active pill each day for 24 days, and then conclude the monthly schedule by taking one of the 4 inactive pills each day for the last 4 days of the 28-day (4 week) schedule.",
            "If you miss a dose you should not take a make-up dose and just resume taking your birth control medication as you would at the regular time the next day.",
            " If you miss 2 or more doses in a row it will be necessary to use a barrier contraceptive method when having sex until you are able to re-establish your medication cycle.",
        ],
    },

    {
        id: "1",
        name: "Cialis",
        pageTitle:
            "Get Cialis Prescription Online - 36 Hours of Sexual Stimulation",
        slug: "cialis",
        ingrediant: "Tadalafil",
        // known: "Known as Loette, Miranova, Microgynon 20ED in the UK",
        description:
            "Cialis (tadalafil) is a prescription erectile dysfunction medication used to treat impotence for men. It relaxes the muscles around blood vessels and increases blood flow for better vascular response to sexual stimulation and the ability to achieve and keep an erection. Cialis is referred to as the ‘weekend pill’ for ED drugs because in comparison to Viagra and Levitra it can work for up to 36 hours and that makes it very appealing for men who want to have more spontaneity when it comes to intimacy with a partner.",
        pageDescription:
            "Cialis (tadalafil) prescription medication is an erectile dysfunction medication for impotence in men. Get Cialis prescription online today at Prescribe Online!",
        bannerImage: "/images/Cialis.png",
        useImage: "/images/ed-use.png",
        category: "ed",
        directions:
            "Cialis is taken as one tablet approximately 30 minutes before sexual activity. It can be taken with or without food and the medication will allow the man an up to 36-hour window of effectiveness during which he can expect to be more able to achieve an erection when sexually stimulated. ",
        cautions:
            "Cialis may not be suitable for men who have an history of heart disease or heart rhythm problems, congestive heart failure, long QT syndrome, seizures, liver or kidney disease, blood cell disorders, bleeding disorders like hemophilia, stomach ulcers, hearing or vision problems, eye disorders like retinitis pigmentosa, Peyronie’s disease (physical penis deformity), or certain health risk concerns related to participation in sexual intercourse.",
        sideeffects:
            "Using Cialis may cause side effects that include flushing, stuffy nose or sinus pain, headache or dizziness, upset stomach or back pain, shortness of breath, light headedness, or swelling in your ankles or feet. ",
        faq: [
            {
                question: "What is Cialis?",
                answer: "Cialis is an erectile dysfunction treatment medication that is part of the phosphodiesterase type 5 class of medications that inhibits the effect of the PDE5 enzyme and makes it unable to produce nitric oxide, which results in muscles around blood vessels entering the penis not being constricted and allowing normal blood flow that lets a man get an erection naturally.",
            },
            {
                question: "How do I use Cialis? ",
                answer: "Depending on the dosage specified in your prescription, you will take either a 2.5mg, 5mg, 10mg, or 20mg Cialis tablet approximately 30 minutes before you foresee having sex with your partner.",
            },
            {
                question:
                    "What are potential side effects associated with using Cialis?",
                answer: "Some men will be more prone to side effects from using this erectile dysfunction medication, and Cialis side effects can include stuffy nose, headaches, dizziness, stomach ache, gas and bloating, flushing and redness, muscle pain, or confusion or disorientation.",
            },
            {
                question: "Is this authentic branded Cialis medication?",
                answer: "Yes, when you order medication online through Prescribe Online you’ll receive the same authentic and branded Cialis medication that you’d receive at any other pharmacy. But here you can get your prescription written online, filled by a licensed pharmacist, and have the genuine branded medication delivered to your home. ",
            },
            {
                question: "Do you have generic Cialis?",
                answer: "Yes, you can get a prescription here and order generic Cialis.",
            },
            {
                question: "Am I able to get refills on my Cialis prescription?",
                answer: "Prescribe Online customers may receive a prescription for up to 12 months (1 year) of medication depending on being approved for it based on their health questionnaire. There may be restrictions on the quantity of medication that can be dispensed at one time. If your prescription for Cialis medication indicates refills then you are able to get the prescription refilled as indicated up to 1 year. You will not need to have a follow-up consultation during that 1-year period. ",
            },
        ],
        uses: [
            "Take Cialis exactly as indicated in your prescription; Cialis is usually taken once each day, and it is common to take it about 30 minutes to one hour before you foresee beginning intimacy.",
            "Do not exceed dosage regulation of one Cialis tablet every 36 hours.",
            "If medication is not sufficiently effective then you should speak with your doctor again and inquire about a dosage increase. ",
            "Do not take more than 1 Cialis pill at a time, or more often than once every 3 days.",
        ],
    },

    {
        id: "2",
        name: "Levitra",
        pageTitle: "Get Levitra Prescription Online to Help Obtain Erection",
        slug: "levitra",
        ingrediant: "Vardenafil",
        // known: "Known as Loette, Miranova, Microgynon 20ED in the UK",
        description:
            "Levitra is an erectile dysfunction treatment medication that helps a man obtain an erection when his natural ability to do so is impeded by a physical condition that limits blood flow into the penis. It works by inhibiting an enzyme so that muscles are more relaxed and blood flows in more naturally when the man is sexually stimulated.",
        pageDescription:
            "Levitra prescription erectile dysfunction medication helps men obtain erection when sexually stimulated. Get Levitra prescription online at Prescribe Online.",
        bannerImage: "/images/Levitra.png",
        useImage: "/images/ed-use.png",
        category: "ed",
        directions:
            "Levitra is taken as one tablet approximately 30 minutes to 1 hour before sexual activity. It can be taken with or without food and the medication will allow the man a 5 hour window of effectiveness during which he can expect to be more able to achieve an erection when sexually stimulated. ",
        cautions:
            "Levitra may not be suitable for men who have an history of heart disease or heart rhythm problems, congestive heart failure, long QT syndrome, seizures, liver or kidney disease, blood cell disorders, bleeding disorders like hemophilia, stomach ulcers, hearing or vision problems, eye disorders like retinitis pigmentosa, Peyronie’s disease (physical penis deformity), or certain health risk concerns related to participation in sexual intercourse.",
        sideeffects:
            "Using Levitra may cause side effects that include flushing, stuffy nose or sinus pain, headache or dizziness, upset stomach or back pain, shortness of breath, light headedness, or swelling in your ankles or feet.  ",
        faq: [
            {
                question: "What is Levitra?",
                answer: "Levitra is an erectile dysfunction treatment medication that is part of the phosphodiesterase type 5 class of medications that inhibits the effect of the PDE5 enzyme and its production of nitric oxide, and in doing so prevent muscles around blood vessels entering the penis from being constricted and impeding the normal blood flow that allows a man to get an erection naturally.",
            },
            {
                question: "How do I use Levitra? ",
                answer: "Depending on the dosage specified in your prescription, you will take either a 5mg, 10mg, or 20mg Levitra tablet approximately one hour before you foresee having sex with your partner.",
            },
            {
                question:
                    "What are potential side effects associated with using Levitra?",
                answer: "Some men will be more prone to side effects from using this erectile dysfunction medication, and Levitra side effects can include stuffy nose, headaches, dizziness, stomach ache, gas and bloating, flushing and redness, pain in your lower back, or confusion or disorientation.",
            },
            {
                question: "Is this authentic branded Levitra medication?",
                answer: "Yes, when you order medication online through Prescribe Online you’ll receive the same authentic and branded Levitra medication that you’d receive at any other pharmacy. But here you can get your prescription written online, filled by a licensed pharmacist, and have the genuine branded medication delivered to your home. ",
            },
            {
                question: "Do you have generic Levitra?",
                answer: "Yes, you can get a prescription here and order generic Levitra",
            },
            {
                question:
                    "Am I able to get refills on my Levitra prescription?",
                answer: "Prescribe Online customers may receive a prescription for up to 12 months (1 year) of medication depending on being approved for it based on their health questionnaire. There may be restrictions on the quantity of medication that can be dispensed at one time. If your prescription for Levitra medication indicates refills then you are able to get the prescription refilled as indicated up to 1 year. You will not need to have a follow-up consultation during that 1-year period.",
            },
        ],
        uses: [
            "Levitra comes in 5mg, 10mg, and 20mg dosages and your doctor will specify which dose is best for you in your prescription.",
            "You then take one Levitra tablet approximately one hour before initiating intimacy with your partner.",
            "After that you will be able to achieve a better erection anytime over the next approximately 5 hours.",
        ],
    },

    {
        id: "3",
        name: "Viagra",
        pageTitle:
            "Get Viagra Prescription Online - Better Love Making Made Easier ",
        slug: "viagra",
        ingrediant: "Sildenafil",
        // known: "Known as Loette, Miranova, Microgynon 20ED in the UK",
        description:
            "Viagra (Sildenafil) is a prescription medication that counters erectile dysfunction in men and allows them to achieve an erection more easily when receiving sexual stimulation. Viagra works by dilating blood vessels so that a more sufficient supply of blood is able to enter the penis and make it erect and enable better participation in lovemaking with their partner.",
        pageDescription:
            "Viagra (Sildenafil) prescription medication is designed to help men with ED achieve erection and have longer intercourse. Get Viagra prescription online today!",
        bannerImage: "/images/Viagra.png",
        useImage: "/images/ed-use.png",
        category: "ed",
        directions:
            "The ideal time to start the Alesse birth control course is the first day (day 1) of your menstrual period. You can also start taking the pills the fifth day of your period as well. It is suggested that the pills must be consumed around the same time regularly, if possible, prior to the evening meal or at sleep time. You can consume the birth control pills with or without food.",
        cautions:
            "Use of Viagra for erectile dysfunction may not be suitable for men with any history of high or low blood pressure (hypertension / hypotension), heart attack or stroke, arrhythmia (irregular heartbeat), pulmonary hypertension secondary to sickle cell anemia, pulmonary veno-occlusive disease, liver or kidney problems, sickle cell anemia, multiple myeloma, or leukemia.",
        sideeffects:
            "Side effects from using Viagra may include erythema, indigestion, headache, insomnia, visual disturbance, nosebleeds, nasal congestion and rhinitis, hearing loss, or priapism – an erection that lasts longer than 4 hours.",
        faq: [
            {
                question: "What is Viagra?",
                answer: "Viagra (sildenafil) is an erectile dysfunction medication that works to promote better blood flow and retention in the penis to allow a man to achieve and maintain an erection as part of enjoying intimacy with their partner. Viagra also works by relaxing muscles found in the walls of blood vessels, and usually starts to work within 30 minutes of taking it. Viagra’s effectiveness can last for up to 5 hours after taking the medication, but your age and health level can shorten that window of time. ",
            },
            {
                question: "How do I use Viagra",
                answer: "Take Viagra exactly as indicated in your prescription; take one Viagra table at your specified dose 30 minutes before you foresee being intimate with your partner.Do not exceed dosage regulation of one Viagra tablet every 24 hours. If medication is not sufficiently effective then you should speak with your doctor again and inquire about a dosage increase. Do not take more than 1 Viagra pill at a time, or more often than once a day.",
            },
            {
                question:
                    "What are potential side effects associated with using Viagra?",
                answer: "ED medication side effects can be pronounced for some men, and with Viagra can include flushing of the skin, headaches or dizziness, abnormal vision, runny or stuff nose or nosebleeds, sleep problems including insomnia, muscle or back pain, and upset stomach. If side effects are severe or worsen then you should stop taking Viagra and speak to your doctor again regarding ED treatment alternatives and getting your ED med prescription online.",
            },
            {
                question: "Is this authentic branded Viagra medication?",
                answer: "Yes, when you order medication online through Prescribe Online you’ll receive the same authentic and branded Viagra medication that you’d receive at any other pharmacy. But here you can get your prescription written online, filled by a licensed pharmacist, and have the genuine branded medication delivered to your home.  ",
            },
            {
                question: "Do you have generic Viagra?",
                answer: "Yes, you can get a prescription here and order generic Viagra online.",
            },
            {
                question:
                    "Am I able to get refills on my Viagra prescription? ",
                answer: "Prescribe Online customers may receive a prescription for up to 12 months (1 year) of medication depending on being approved for it based on their health questionnaire. There may be restrictions on the quantity of medication that can be dispensed at one time. If your prescription for Viagra erectile dysfunction medication indicates refills then you are able to get the prescription refilled as indicated up to 1 year. You will not need to have a follow-up consultation during that 1-year period.",
            },
        ],
        uses: [
            "Before starting on Viagra, tell your doctor of any other existing medical conditions and any Rx medications you are currently taking.",
            "You can take Viagra with or without food.",
            "Take your Viagra 30 minutes to one hour before sexual activity. Some patients find that taking Viagra up to four hours before sexual activity works well for them.",
        ],
    },
];

export const getProductFromMock = (slug): ProductMock =>
    products.find((x) => x.slug === slug);
export const getProductFromProductList = (id): ProductMock =>
    products.find((x) => x.id === id.toString());

export const getProductsByCategory = (category): ProductMock =>
    products.filter((x) => x.category === category);

export default products;
