import React from "react";
import { Radio } from "antd";
import S from "styled-components";

interface Props {
    children: [];
    callback: any;
    selectedValue: any;
}

const Container = S.div`
	text-align:left;
	font-family: "Poppins", sans-serif;
    & .ant-radio-group {
        width: 100%;
    }
`;

const RadioGroup = ({ children = [], callback, selectedValue }: Props) => {
    const onChange = (e) => {
        callback(e.target.value);
    };

    return (
        <Container>
            {
                <Radio.Group onChange={onChange} value={selectedValue}>
                    {children}
                </Radio.Group>
            }
        </Container>
    );
};

RadioGroup.defaultProps = {};

export default RadioGroup;
