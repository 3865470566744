import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import Text from "../UiWrappers/Text";
import { Button } from "../Button";
import RadioButton, { RadioGroup } from "../RadioGroup";
import Row from "../UiWrappers/Row";
import Column from "../UiWrappers/Column";
import S from "styled-components";
import Spacer from "../UiWrappers/Spacer";
import message from "../../util/message";
import Theme from "../Theme";
import { Container } from "react-bootstrap";
import { useWindowSize } from "../../util";
import theme from "../Theme";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";

const ErrorMessage = S.div`
    color: ${theme.colors.errormessage};
    font-size: 15px;
    text-align: left;
    font-weight:400;
    line-height:18px;
`;

const Label = S.label`
    display: block;
    color: ${Theme.colors.primary800};
    font-size: 18px;
    line-height: 25.2px;
    font-weight: 600;
    margin-Bottom: 7px;
    margin-top: 20px;
    text-align: left;
    font-family: Poppins;
`;

const Background = styled.div`
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    /* Gray Tones/Text 900 */
    background: #444444;
    opacity: 0.95;
`;

const ModalWrapper = styled.div`
    position: absolute;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 5vh;
    padding-bottom: 5vh;
    background: #fafafa;
    border-radius: 10px;
    top: 1rem;
    left: 1rem;
    right: 1rem;
    @media ${theme.breakpoints.xs} {
        padding-left: 8%;
        padding-right: 8%;
    }
    @media ${theme.breakpoints.sm} {
        width: 60%;
        top: 50%;
        left: 50%;
        right: auto;
        transform: translate(-50%, -50%);
    }
    @media ${theme.breakpoints.md} {
        width: 30%;
    }
    @media ${theme.breakpoints.lg} {
        width: 35%;
    }
`;

export const SelectLocationModal = ({ showModal, setShowModal }) => {
    const [reRender] = React.useState(0);
    const { width } = useWindowSize();
    const isMobile = () => width <= theme.mobileBreakpoint;
    const userLocation = useSelector((state) => state.appReducer.userLocation);

    const [userLocationSelection, setUserLocationSelection] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const router = useRouter();

    const modalRef = useRef();
    const closeModal = (e) => {
        if (modalRef.current === e.target) {
            setShowModal(false);
        }
    };
    useEffect(() => {
        if (userLocation === "US" || userLocation === "CA") {
            setUserLocationSelection(userLocation);
        } else {
            setUserLocationSelection("Auto Detect");
        }
    }, [reRender]);

    const submit = async () => {
        if (userLocationSelection === null) {
            return message.create({
                type: "error",
                text: "Please choose a location.",
            });
        }

        if (userLocationSelection === "US" || userLocationSelection === "CA") {
            storeLocationAndReloadPage(userLocationSelection);
        } else {
            await checkLocation();
        }
    };

    const checkLocation = async () => {
        clearLocation();
        router.reload(window.location.pathname);
    };

    const storeLocationAndReloadPage = (location) => {
        localStorage.setItem("userLocation", location);
        router.reload(window.location.pathname);
    };

    const clearLocation = () => {
        localStorage.removeItem("userLocation");
    };

    return (
        <>
            {showModal ? (
                <Background onClick={closeModal} ref={modalRef}>
                    <ModalWrapper showModal={showModal}>
                        <Container>
                            <Text align={"left"} type={"h2"}>
                                Choose Location
                            </Text>

                            <Label>
                                Choose one of the region we are available in:
                            </Label>

                            <RadioGroup
                                selectedValue={userLocationSelection}
                                callback={(value) => {
                                    setUserLocationSelection(value);
                                }}>
                                <RadioButton label="Canada" value={"CA"} />
                                <RadioButton
                                    label="United States"
                                    value={"US"}
                                />
                                <RadioButton
                                    label="Auto Detect"
                                    value={"Auto Detect"}
                                />

                                {/*<RadioButton*/}
                                {/*	label= "Not known"*/}
                                {/*	value={null}*/}
                                {/*/>*/}
                            </RadioGroup>
                            <Spacer height="13px" />
                            {errorMessage != undefined ? (
                                // <Disclaimer>
                                //     <Text type="body7">{errorMessage}</Text>
                                // </Disclaimer>
                                <ErrorMessage>{errorMessage}</ErrorMessage>
                            ) : (
                                <></>
                            )}
                            <Spacer height="13px" />

                            <Row>
                                <Column
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    textAlign={"right"}>
                                    <Button
                                        onClick={() =>
                                            setShowModal((prev) => !prev)
                                        }
                                        type={"secondary"}
                                        title={"Cancel"}
                                        style={
                                            isMobile()
                                                ? {
                                                      width: "100%",
                                                      marginBottom: "1rem",
                                                  }
                                                : {
                                                      width: "auto",
                                                      marginRight: "0.5rem",
                                                      display: "inline-block",
                                                  }
                                        }
                                    />
                                    <Button
                                        type={"primary"}
                                        title={"Select"}
                                        onClick={() => submit()}
                                        style={
                                            isMobile()
                                                ? {
                                                      width: "100%",
                                                      marginBottom: "1rem",
                                                  }
                                                : {
                                                      width: "auto",
                                                      display: "inline-block",
                                                  }
                                        }
                                    />
                                </Column>
                            </Row>
                        </Container>
                    </ModalWrapper>
                </Background>
            ) : null}
        </>
    );
};
