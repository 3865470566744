import React, { useReducer } from "react";

type stateType = {
    section: number;
    activeQuestion: number;
    activeGroup: number;
    answers: object;
    dispatch?: any;
    address: number;
    existingAnswersWithIds: object;
};

const initialState: stateType = {
    section: 1,
    activeQuestion: 0,
    activeGroup: 0,
    answers: {},
    address: 0,
    existingAnswersWithIds: {},
};

const VisitState = React.createContext(initialState);

const VisitStateProvider = ({ children }) => {
    const reducer = (state: stateType, action) => {
        switch (action.type) {
            case "setSection":
                return {
                    ...state,
                    section: action.payload.section,
                };
            case "setActiveQuestion":
                return {
                    ...state,
                    activeQuestion: action.payload.activeQuestion,
                };
            case "setActiveGroup":
                return {
                    ...state,
                    activeGroup: action.payload.activeGroup,
                };
            case "setAnswers":
                return {
                    ...state,
                    answers: action.payload.answers,
                };
            case "setAddress":
                return {
                    ...state,
                    address: action.payload.addressId,
                };
            case "setExistingAnswersWithIds":
                return {
                    ...state,
                    existingAnswersWithIds:
                        action.payload.existingAnswersWithIds,
                };
            case "resetState":
                return { ...initialState };
            default:
                return { ...initialState };
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <VisitState.Provider value={{ ...state, dispatch }}>
            {children}
        </VisitState.Provider>
    );
};

const useVisitState = () => {
    const context = React.useContext(VisitState);
    if (context === undefined) {
        throw new Error(
            "useVisitState must be used within a VisitStateProvider"
        );
    }
    return context;
};

export { VisitStateProvider, useVisitState };
