import React from "react";
import { Col } from "antd";
import "antd/dist/antd.css";
import { useWindowSize } from "../../util";
import theme from "../Theme";

interface Props {
    sm?: any;
    md?: any;
    lg?: any;
    textAlign?: any;
    padding?: any;
    paddingTop?: any;
    paddingBottom?: any;
    paddingLeft?: any;
    paddingRight?: any;
    marginTop?: any;
    marginBottom?: any;
    marginRight?: any;
    children?: any;
    mobileOrder?: any;
    flexDirection?: any;
    display?: any;
    className?: any;
}

const CustomColumn = ({
    sm,
    md,
    lg,
    textAlign,
    padding,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    marginTop,
    marginBottom,
    marginRight,
    children,
    mobileOrder,
    flexDirection,
    display,
    className,
}: Props) => {
    const { width } = useWindowSize();
    const isMobile = () => width <= theme.mobileBreakpoint;
    return (
        <>
            <Col
                className={className}
                order={isMobile() ? mobileOrder : 0}
                xs={sm}
                sm={sm}
                md={md}
                lg={lg}
                style={{
                    textAlign: textAlign,
                    padding: padding,
                    paddingTop: paddingTop,
                    paddingBottom: paddingBottom,
                    paddingLeft: paddingLeft,
                    paddingRight: paddingRight,
                    marginTop: marginTop,
                    marginBottom: marginBottom,
                    marginRight: marginRight,
                    flexDirection: flexDirection,
                    display: display,
                    // width: isMobile() ? (sm === 24 ? "100%" : "") : "",
                }}>
                {children}
            </Col>
        </>
    );
};

CustomColumn.defaultProps = {
    textAlign: "center",
};

export default CustomColumn;
