import axios from "axios";
import { store } from "../pages/_app";

const apiCall = async (
    url,
    method,
    headers,
    body,
    params,
    userLocation = undefined
) => {
    const response = {
        status: 500,
        message: "Something went wrong. Please try again later.",
        data: [],
    };

    let selectedLocation = undefined;
    if (userLocation) {
        selectedLocation = userLocation;
    } else {
        selectedLocation = store.getState().appReducer.userLocation;
    }

    let apiBaseURL = undefined;
    if (selectedLocation === "CA") {
        apiBaseURL = axios.create({
            baseURL: process.env.NEXT_APP_API,
        });
    } else if (selectedLocation === "US") {
        apiBaseURL = axios.create({
            baseURL: process.env.NEXT_APP_API_US,
        });
    }

    // console.log("LOL", selectedLocation + " " + url);

    if (!apiBaseURL) {
        response.message =
            "Sorry, We are currently not available in your region. Want to access your account associated to a different region? You can select your region from the top to sign in.";
        return response;
    }

    const config = {
        url,
        method,
        data: body,
        params,
        headers,
    };

    await apiBaseURL
        .request(config)
        .then((apiResponse) => {
            response.status = apiResponse.status;
            response.message = apiResponse.data.message;
            response.data = apiResponse.data.data;
        })
        .catch((err) => {
            if (err.response) {
                response.status = err.response.status;
                response.message = err.response.data.message;
                response.data = err.response.data.data;
            }
        });
    return response;
};

export default apiCall;
