import React, { useState } from "react";
import S from "styled-components";
import theme from "../Theme";
import Link from "next/link";
import { useWindowSize } from "../../util";

const Types = {
    primary: {
        background: "#FC763A",
        minWidth: "auto",
        border: "1px solid #E7E8FB;",
        borderRadius: "10px;",
        boxShadow: "0px 0px 10px #E7E8FB;",
        contentColor: theme.colors.white,
        weight: "600",
        fontSize: "18px",
        hoverBackground: "#FC763A",
        hoverContentColor: theme.colors.text900,
    },
    secondary: {
        background: theme.colors.white,
        minWidth: "auto",
        border: "1px solid #E7E8FB;",
        borderRadius: "10px;",
        boxShadow: "0px 0px 10px #E7E8FB;",
        contentColor: "#0028C9",
        weight: "600",
        fontSize: "18px",
        hoverBackground: "#7681E9",
        hoverContentColor: theme.colors.white,
    },
};

const Container = S.button`
    background-color: ${(p) => p.background};
    padding: 8px 30px;
    min-width: ${(p) => p.minWidth};
    border: ${(p) => p.border};
    border-radius: ${(p) => p.borderRadius};
    box-shadow: ${(p) => p.boxShadow};
	height: ${(p) => p.height};
	display:flex;
	justify-content: center; 
	align-items: center; 
    margin-top: ${(p) => p.marginTop};
    margin-bottom: ${(p) => p.marginTop};    
    cursor: pointer;
    white-space: nowrap;
	@media ${theme.breakpoints.xs} {
		width: 100%;
	}
	@media ${theme.breakpoints.sm} {
		width: auto;
	}
`;

// const LinkButtonContainer = S.a`
//     background-color: ${(p) => p.background};
//     padding: 8px 30px;
//     min-width: ${(p) => p.minWidth};
//     border: ${(p) => p.border};
//     border-radius: ${(p) => p.borderRadius};
//     box-shadow: ${(p) => p.boxShadow};
//     max-height: auto;
// 	height: 50px;
// 	display:flex;
// 	justify-content: center;
// 	align-items: center;
//     margin-top: ${(p) => p.marginTop};
//     margin-bottom: ${(p) => p.marginTop};
//     cursor: pointer;
// `;

const Content = S.div`
    color: ${(p) => p.color};
    font-weight: ${(p) => p.weight};
    font-size: ${(p) => p.size};
	line-height: 1;
    white-space: nowrap;
`;
const Icon = S.img`
	width: 35px;
`;

interface Props {
    type: string;
    title: string;
    mt?: string;
    mb?: string;
    rightIcon?: any;
    rightIconOnHover?: any;
    fullWidth?: boolean;
    onClick?: any;
    href?: string;
    style?: any;
}

const Inner = React.forwardRef(
    (
        {
            setOver,
            mt,
            mb,
            backgroundColor,
            type,
            onClick,
            contentColor,
            title,
            rightIcon,
            buttonIcon,
            style,
        },
        ref
    ) => {
        const { width } = useWindowSize();
        const isMobile = () => width <= theme.mobileBreakpoint;
        return (
            <Container
                height={isMobile() ? "60px" : "50px"}
                style={style}
                onMouseOver={() => setOver(true)}
                onMouseOut={() => setOver(false)}
                marginTop={mt}
                marginBottom={mb}
                minWidth={Types[type].minWidth}
                background={backgroundColor}
                border={Types[type].border}
                borderRadius={Types[type].borderRadius}
                boxShadow={Types[type].boxShadow}
                onClick={onClick}>
                <Content
                    weight={Types[type].weight}
                    color={contentColor}
                    size={Types[type].fontSize}>
                    {title}
                    {rightIcon ? <Icon src={buttonIcon} /> : ""}
                </Content>
            </Container>
        );
    }
);

const Main = ({
    title,
    mt,
    mb,
    type,
    rightIcon,
    rightIconOnHover,
    onClick,
    href,
    style,
}: Props) => {
    const [over, setOver] = useState(false);

    let backgroundColor = "#FC763A";
    let contentColor = "#fff";
    let buttonIcon = "";

    if (over) {
        backgroundColor = Types[type].hoverBackground;
        contentColor = Types[type].hoverContentColor;
        buttonIcon = rightIconOnHover;
    } else {
        backgroundColor = Types[type].background;
        contentColor = Types[type].contentColor;
        buttonIcon = rightIcon;
    }

    return (
        <Link href={href}>
            <Inner
                style={style}
                type={type}
                setOver={setOver}
                backgroundColor={backgroundColor}
                contentColor={contentColor}
                buttonIcon={buttonIcon}
                mb={mb}
                mt={mt}
                onClick={onClick}
                rightIcon={rightIcon}
                title={title}
            />
        </Link>
    );
};

Main.defaultProps = {
    type: "primary",
    linkButton: false,
};

export { Main as LinkButton };
