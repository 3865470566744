const size = {
    xs: "320px",
    sm: "768px",
    md: "1390px",
    lg: "1200px",
};
const device = {
    xs: `(min-width: ${size.xs})`,
    sm: `(min-width: ${size.sm})`,
    md: `(min-width: ${size.md})`,
    lg: `(min-width: ${size.lg})`,
};
const theme = {
    colors: {
        primary800: "#0028C9",
        primary500: "#3B4BDF",
        primary300: "#7682E9",
        primary100: "#C5C7F5",
        primary80: "#DCDDF8",
        primary50: "#E7E8FB",
        secondary400: "#FC763A",
        secondary200: "#FEAF8D",
        secondary100: "#FECEBA",
        secondary50: "#FBEAE6",
        text900: "#444444",
        shading500: "#9E9E9E",
        shading200: "#EEEEEE",
        shading50: "#FAFAFA",
        orange: "#FC763A",
        white: "#FFFFFF",
        errormessage: "#FC763A",
        editcolor: "#7682E9",
    },
    fonts: ["sans-serif", "Roboto"],
    fontSizes: {
        H1: {
            font: "55px",
            weight: "bold",
            style: "normal",
        },
        H2: {
            font: "40px",
            weight: "bold",
            style: "normal",
        },
        H3: {
            font: "26px",
            weight: "bold",
            style: "normal",
        },
        H4: {
            font: "24px",
            weight: "bold",
            style: "normal",
        },
        H5: {
            font: "12px",
            weight: "bold",
            style: "normal",
        },
        H6: {
            font: "10px",
            weight: "bold",
            style: "normal",
        },
        Body1: {
            font: "25px",
            weight: "normal",
            style: "normal",
        },
        Body2: {
            font: "20px",
            weight: "normal",
            style: "normal",
        },
        Body3: {
            font: "20px",
            weight: "400",
            style: "italic",
        },
        Body4: {
            font: "18px",
            weight: "bold",
            style: "normal",
        },
        Body5: {
            font: "18px",
            weight: "400",
            style: "normal",
        },
        Body6: {
            font: "18px",
            weight: "normal",
            style: "normal",
        },
        Body7: {
            font: "16px",
            weight: "400",
            style: "normal",
        },
        Body8: {
            font: "16px",
            weight: "600",
            style: "normal",
        },
        Body9: {
            font: "14px",
            weight: "400",
            style: "normal",
        },
        Body10: {
            font: "14px",
            weight: "normal",
            style: "normal",
        },

        Body11: {
            font: "20px",
            weight: "bold",
            style: "normal",
        },

        Body12: {
            font: "18px",
            weight: "600",
            style: "normal",
        },

        Body13: {
            font: "20px",
            weight: "600",
            style: "normal",
        },

        Body14: {
            font: "25px",
            weight: "500",
            style: "normal",
        },

        Body15: {
            font: "18px",
            weight: "normal",
            style: "italic",
        },
        Body16: {
            font: "10px",
            weight: "normal",
            style: "normal",
        },

        Link1: {
            font: "20px",
            weight: "400",
            style: "normal",
        },
        Link2: {
            font: "18px",
            weight: "400",
            style: "normal",
        },
        Link3: {
            font: "14px",
            weight: "300",
            style: "normal",
        },
    },
    breakpoints: {
        ...device,
    },
    maxWidth: "1390px",
    mobileBreakpoint: 992,
    // mobileBreakpoint: 1390,
};
export default theme;
