import React from "react";
import { Radio } from "antd";
import S from "styled-components";
import theme from "../Theme";

const RadioBtn = S(Radio)`
    display: inline-block;
    padding: 5px 0px;
    &:after {
        display: none;
    }
    .ant-radio+* {
        padding: 0;
    }
    .ant-radio-wrapper > .ant-radio {
        margin-right: 10px;
    }
    & > .ant-radio {
        display: none;
    }
    & > span {
        display: flex;
    }
    & >  span:before {
        flex-shrink: 0;
        content: " ";
        display: inline-block;
        position: relative;
        top: 1px;
        margin: 0 10px 0 0;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid ${theme.colors.primary100};
        background-color: ${theme.colors.primary50};
        box-shadow: 0px 4px 14px 10px rgba(231, 232, 251, 0.15);
      }
      
     & > .ant-radio-checked + span:after {
        border-radius: 50%;
        width: 12px;
        height: 12px;
        position: absolute;
        content: " ";
        display: block;
        background: ${theme.colors.primary800};
        top: 10px;
        left: 4px;
      }
`;

interface Props {
    value: string;
    label: string;
}

const Container = S.div`
	text-align:left;
`;

const RadioButton = ({ value, label }: Props) => {
    return (
        <Container>
            <RadioBtn value={value}>{label}</RadioBtn>
        </Container>
    );
};

RadioButton.defaultProps = {
    value: "radio",
    label: "RadioButton",
};

export default RadioButton;
