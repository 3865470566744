import React, { useState } from "react";
import S from "styled-components";
import theme from "../Theme";
import Link from "next/link";
import { useWindowSize } from "../../util";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Types = {
    primary: {
        background: "#FC763A",
        minWidth: "auto",
        border: "1px solid #E7E8FB;",
        borderRadius: "10px;",
        boxShadow: "0px 0px 10px #E7E8FB;",
        contentColor: theme.colors.white,
        weight: "600",
        fontSize: "18px",
        hoverBackground: "#FC763A",
        hoverContentColor: theme.colors.text900,
    },
    secondary: {
        background: theme.colors.white,
        minWidth: "auto",
        border: "1px solid #E7E8FB;",
        borderRadius: "10px;",
        boxShadow: "0px 0px 10px #E7E8FB;",
        contentColor: "#0028C9",
        weight: "600",
        fontSize: "18px",
        hoverBackground: "#7681E9",
        hoverContentColor: theme.colors.white,
    },
};

const Container = S.button`
    background-color: ${(p) => p.background};
    padding: 8px 30px;
    min-width: ${(p) => p.minWidth};
    border: ${(p) => p.border};
    border-radius: ${(p) => p.borderRadius};
    box-shadow: ${(p) => p.boxShadow};
    max-height: auto;
	height: ${(p) => p.height};
	display:flex;
	justify-content: center; 
	align-items: center; 
    margin-top: ${(p) => p.marginTop};
    margin-bottom: ${(p) => p.marginTop};    
    cursor: pointer;
	font-family: 'Poppins';	
	@media ${theme.breakpoints.xs} {
		width: 100%;
	}
	@media ${theme.breakpoints.sm} {
		width: auto;
	}
`;

const StyledIcon = S(FontAwesomeIcon)`
	font-weight:200;
`;

const Content = S.div`
    color: ${(p) => p.color};
    font-weight: ${(p) => p.weight};
    font-size: ${(p) => p.size};
	display:flex;
	align-items:center;
`;

interface Props {
    type: string;
    title: string;
    mt?: string;
    mb?: string;
    leftIcon?: any;
    leftIconOnHover?: any;
    rightIcon?: any;
    rightIconOnHover?: any;
    fullWidth?: boolean;
    onClick?: any;
    href?: string;
    style?: any;
}

const Inner = React.forwardRef(
    (
        {
            setOver,
            mt,
            mb,
            backgroundColor,
            type,
            onClick,
            contentColor,
            title,
            rightIcon,
            leftIcon,
            buttonIcon,
            style,
        },
        ref
    ) => {
        const { width } = useWindowSize();
        const isMobile = () => width <= theme.mobileBreakpoint;
        return (
            <Container
                height={isMobile() ? "60px" : "50px"}
                style={style}
                onMouseOver={() => setOver(true)}
                onMouseOut={() => setOver(false)}
                marginTop={mt}
                marginBottom={mb}
                minWidth={Types[type].minWidth}
                background={backgroundColor}
                border={Types[type].border}
                borderRadius={Types[type].borderRadius}
                boxShadow={Types[type].boxShadow}
                onClick={onClick}>
                <Content
                    weight={Types[type].weight}
                    color={contentColor}
                    size={Types[type].fontSize}>
                    {leftIcon ? (
                        <StyledIcon
                            icon={leftIcon ? leftIcon : ""}
                            width={"17px"}
                            // width={type === "iconlink" ? "17px" : "30px"}
                            color={contentColor}
                            style={{
                                marginRight:
                                    type === "iconlink" ? "5px" : "10px",
                            }}
                        />
                    ) : null}
                    {title}
                    {rightIcon ? (
                        <StyledIcon
                            icon={rightIcon ? rightIcon : ""}
                            width={"17px"}
                            color={contentColor}
                            style={{
                                marginLeft:
                                    type === "iconlink" ? "5px" : "10px",
                            }}
                        />
                    ) : null}
                </Content>
            </Container>
        );
    }
);

const Main = ({
    title,
    mt,
    mb,
    type,
    rightIcon,
    rightIconOnHover,
    leftIcon,
    leftIconOnHover,
    onClick,
    href,
    style,
}: Props) => {
    const [over, setOver] = useState(false);

    let backgroundColor = "#FC763A";
    let contentColor = "#fff";
    let buttonIcon = "";

    if (over) {
        backgroundColor = Types[type].hoverBackground;
        contentColor = Types[type].hoverContentColor;
        buttonIcon = rightIconOnHover;
    } else {
        backgroundColor = Types[type].background;
        contentColor = Types[type].contentColor;
        buttonIcon = rightIcon;
    }

    return href ? (
        <Link href={href}>
            <Inner
                style={style}
                type={type}
                setOver={setOver}
                backgroundColor={backgroundColor}
                contentColor={contentColor}
                buttonIcon={buttonIcon}
                mb={mb}
                mt={mt}
                onClick={onClick}
                leftIcon={leftIcon}
                rightIcon={rightIcon}
                title={title}
            />
        </Link>
    ) : (
        <Inner
            style={style}
            type={type}
            setOver={setOver}
            backgroundColor={backgroundColor}
            contentColor={contentColor}
            buttonIcon={buttonIcon}
            mb={mb}
            mt={mt}
            onClick={onClick}
            rightIcon={rightIcon}
            leftIcon={leftIcon}
            title={title}
        />
    );
};

Main.defaultProps = {
    type: "primary",
    linkButton: false,
};

export { Main as Button };
