import React from "react";
import S from "styled-components";
import theme from "../Theme";
import Row from "../UiWrappers/Row";
import Column from "../UiWrappers/Column";
import Link from "next/link";
import { useWindowSize } from "../../util";
import { getProductsByCategory } from "../Product/mocks/mock";
import { useSelector } from "react-redux";

const FooterContainer = S.div`
    width: 100%;
	padding-top: 30px;
    background-color: ${theme.colors.shading200};
	max-width:1260px;
	margin: auto;
    &:first-child {
        background-color: transparent;
    }
`;
const FooterTitle = S.a`
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 120%;
    color: ${theme.colors.primary800};
`;

const LinkContainer = S.a` 
	display: block;
 	cursor:  pointer;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 2rem;
    color: ${theme.colors.text900};`;

const FooterTitleLink = ({ href, children }) => {
    return (
        <Link href={href}>
            <FooterTitle href={href}>{children}</FooterTitle>
        </Link>
    );
};

const FooterLink = ({ href, children }) => {
    return (
        <Link href={href}>
            <LinkContainer href={href}>{children}</LinkContainer>
        </Link>
    );
};
const RightsText = S.p`
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    color: ${theme.colors.shading500};
`;

const FullLogo = () => {
    return (
        <svg
            width="170"
            height="71"
            viewBox="0 0 191 71"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.9098 24.0581C22.9098 30.5278 17.8743 35.4409 11.3041 35.4409H8.18691V46.1913H0.75354V12.6266H11.3041C17.8743 12.6266 22.9098 17.5397 22.9098 24.0581ZM15.2846 24.0581C15.2846 21.5772 13.5102 19.7287 11.1123 19.7287H8.18691V28.3388H11.1123C13.5102 28.3457 15.2846 26.5389 15.2846 24.0581Z"
                fill="#0028C9"
            />
            <path
                d="M38.8797 20.9935V28.3388C35.0431 28.3388 33.0289 29.6036 33.0289 33.6897V46.1913H26.0272V21.3827H33.0289V23.7663C34.5636 21.8691 36.5778 20.9935 38.8797 20.9935Z"
                fill="#0028C9"
            />
            <path
                d="M64.6804 36.08H47.5596C48.4229 38.8527 50.9646 40.6039 55.1369 40.6039C57.6786 40.6039 60.4601 39.9716 63.1937 38.6095V44.8846C60.6519 46.1007 57.6306 46.7818 54.4655 46.7818C45.2097 46.7818 40.0303 41.1877 40.0303 33.9397C40.0303 26.3998 45.5934 20.8057 52.8829 20.8057C60.1244 20.8057 65.0161 26.2538 65.0161 33.0641C65.0161 34.3288 64.8242 35.3503 64.6804 36.08ZM47.5117 31.4101H57.8225C57.5347 29.0266 55.6644 26.9349 52.8829 26.9349C50.3411 26.9279 48.279 28.6861 47.5117 31.4101Z"
                fill="#0028C9"
            />
            <path
                d="M67.7017 44.7873V38.4149C69.62 39.8743 72.3056 41.0904 74.2239 41.0904C75.5667 41.0904 76.4299 40.5066 76.4299 39.4851C76.4299 36.2259 67.4139 35.9341 67.4139 28.2969C67.4139 23.6757 70.8189 20.8057 75.7585 20.8057C77.8686 20.8057 80.1226 21.3408 81.945 22.1677V28.1023C80.6022 27.2754 78.3961 26.4971 76.6697 26.4971C75.183 26.4971 74.2239 27.0808 74.2239 27.9564C74.2239 30.7291 83.2398 31.1183 83.2398 39.2905C83.2398 44.1063 80.1706 46.7818 74.7993 46.7818C71.9699 46.7818 69.572 46.0521 67.7017 44.7873Z"
                fill="#0028C9"
            />
            <path
                d="M85.206 33.7869C85.206 26.5876 90.6731 20.7989 98.4902 20.7989C100.6 20.7989 102.758 21.2367 104.629 22.0636V29.0684C103.238 28.2901 101.224 27.7064 99.2095 27.7064C95.0372 27.7064 92.3996 30.2845 92.3996 33.7869C92.3996 37.2893 95.0372 39.8675 99.2095 39.8675C101.224 39.8675 103.238 39.2838 104.629 38.5054V45.5172C102.758 46.3441 100.6 46.7819 98.4902 46.7819C90.6731 46.7819 85.206 41.1878 85.206 33.7869Z"
                fill="#0028C9"
            />
            <path
                d="M122.085 20.9935V28.3388C118.249 28.3388 116.234 29.6036 116.234 33.6897V46.1913H109.233V21.3827H116.234V23.7663C117.769 21.8691 119.783 20.9935 122.085 20.9935Z"
                fill="#0028C9"
            />
            <path
                d="M125.346 21.3824H132.348V46.1911H125.346V21.3824Z"
                fill="#0028C9"
            />
            <path
                d="M163.712 33.7869C163.712 41.1809 158.629 46.775 151.915 46.775C148.941 46.775 146.399 45.6562 144.529 43.759V46.1912H137.527V11.1673H144.529V23.8148C146.399 21.9177 148.941 20.7989 151.915 20.7989C158.629 20.7989 163.712 26.393 163.712 33.7869ZM156.518 33.7869C156.518 30.2845 153.977 27.7064 150.524 27.7064C147.071 27.7064 144.529 30.2845 144.529 33.7869C144.529 37.2893 147.071 39.8675 150.524 39.8675C153.977 39.8675 156.518 37.2963 156.518 33.7869Z"
                fill="#0028C9"
            />
            <path
                d="M190.664 36.08H173.544C174.407 38.8527 176.949 40.6039 181.121 40.6039C183.663 40.6039 186.444 39.9716 189.178 38.6095V44.8846C186.636 46.1007 183.615 46.7818 180.449 46.7818C171.194 46.7818 166.014 41.1877 166.014 33.9397C166.014 26.3998 171.577 20.8057 178.867 20.8057C186.108 20.8057 191 26.2538 191 33.0641C191 34.3288 190.808 35.3503 190.664 36.08ZM173.496 31.4101H183.806C183.519 29.0266 181.648 26.9349 178.867 26.9349C176.325 26.9279 174.263 28.6861 173.496 31.4101Z"
                fill="#0028C9"
            />
            <path
                d="M0 63.5851C0 59.36 3.23369 56.1634 7.53614 56.1634C11.7838 56.1634 15.0449 59.36 15.0449 63.5851C15.0449 67.8102 11.7838 70.9999 7.53614 70.9999C3.23369 70.9999 0 67.8033 0 63.5851ZM11.3453 63.5851C11.3453 61.3336 9.70107 59.6658 7.53614 59.6658C5.3164 59.6658 3.67215 61.3336 3.67215 63.5851C3.67215 65.8366 5.3164 67.5045 7.53614 67.5045C9.70107 67.4975 11.3453 65.8297 11.3453 63.5851Z"
                fill="#FC763A"
            />
            <path
                d="M28.6646 60.5831V70.6733H25.1021V61.7783C25.1021 60.4997 24.3073 59.6658 23.1016 59.6658C21.7587 59.6658 20.8818 60.5275 20.8818 61.8339V70.6733H17.3193V56.4969H20.8818V57.8034C21.7587 56.7471 22.9371 56.1634 24.4169 56.1634C26.9655 56.1634 28.6646 57.9146 28.6646 60.5831Z"
                fill="#FC763A"
            />
            <path
                d="M31.5968 50.6599H35.1594V70.6666H31.5968V50.6599Z"
                fill="#FC763A"
            />
            <path
                d="M37.8177 52.3553C37.8177 51.0489 38.8042 50.076 40.0922 50.076C41.3802 50.076 42.3668 51.0489 42.3668 52.3553C42.3668 53.634 41.3802 54.5791 40.0922 54.5791C38.8042 54.5791 37.8177 53.634 37.8177 52.3553ZM38.311 56.4971H41.8735V70.6734H38.311V56.4971Z"
                fill="#FC763A"
            />
            <path
                d="M56.3706 60.5831V70.6733H52.8081V61.7783C52.8081 60.4997 52.0134 59.6658 50.8076 59.6658C49.4648 59.6658 48.5879 60.5275 48.5879 61.8339V70.6733H45.0253V56.4969H48.5879V57.8034C49.4648 56.7471 50.6432 56.1634 52.123 56.1634C54.6716 56.1634 56.3706 57.9146 56.3706 60.5831Z"
                fill="#FC763A"
            />
            <path
                d="M72.4836 64.8292H62.2345C62.7277 66.636 64.3172 67.8034 66.9206 67.8034C68.51 67.8034 70.1817 67.3587 71.7163 66.5526V69.8048C70.2913 70.5553 68.4552 71.0001 66.5917 71.0001C61.3849 71.0001 58.4253 67.8034 58.4253 63.6617C58.4253 59.3532 61.5768 56.1566 65.7422 56.1566C69.8802 56.1566 72.6755 59.2976 72.6755 63.1336C72.6755 63.7798 72.5932 64.3914 72.4836 64.8292ZM62.2071 62.2788H69.0307C68.8937 60.7222 67.6057 59.3323 65.7148 59.3323C64.0157 59.3323 62.6455 60.4998 62.2071 62.2788Z"
                fill="#FC763A"
            />
            <path
                d="M132.54 6.69208H130.909V10.9241H132.54V6.69208Z"
                fill="#FC763A"
            />
            <path
                d="M130.909 13.8428C130.909 15.0103 129.971 15.9553 128.827 15.9553C127.683 15.9553 126.744 15.0033 126.744 13.8428V12.578H125.113V13.8428C125.113 15.9206 126.778 17.6093 128.827 17.6093C130.875 17.6093 132.54 15.9206 132.54 13.8428V12.578H130.909V13.8428Z"
                fill="#FC763A"
            />
            <path
                d="M126.744 3.76647C126.744 2.599 127.683 1.65391 128.827 1.65391C129.971 1.65391 130.909 2.60595 130.909 3.76647V5.03122H132.54V3.76647C132.54 1.68866 130.875 0 128.827 0C126.778 0 125.113 1.68866 125.113 3.76647V5.03122H126.744V3.76647Z"
                fill="#FC763A"
            />
            <path
                d="M133.814 5.03806H132.547V6.69196H133.814C134.965 6.69196 135.897 7.64401 135.897 8.80452C135.897 9.97199 134.958 10.9171 133.814 10.9171H132.547V12.571H133.814C135.862 12.571 137.527 10.8823 137.527 8.80452C137.527 6.72671 135.862 5.03806 133.814 5.03806Z"
                fill="#FC763A"
            />
            <path
                d="M130.916 10.9169H126.743V12.5708H130.916V10.9169Z"
                fill="#FC763A"
            />
            <path
                d="M123.88 10.9171C122.729 10.9171 121.797 9.96504 121.797 8.80452C121.797 7.63706 122.736 6.69196 123.88 6.69196H125.106V5.03806H123.88C121.832 5.03806 120.167 6.72671 120.167 8.80452C120.167 10.8823 121.832 12.571 123.88 12.571H125.106V10.9171H123.88Z"
                fill="#FC763A"
            />
            <path
                d="M132.54 5.03806H130.909V6.69197H132.54V5.03806Z"
                fill="#FC763A"
            />
            <path
                d="M132.54 10.9169H130.909V12.5708H132.54V10.9169Z"
                fill="#FC763A"
            />
            <path
                d="M126.744 5.03806H125.113V6.69197H126.744V5.03806Z"
                fill="#FC763A"
            />
            <path
                d="M126.744 10.9169H125.113V12.5708H126.744V10.9169Z"
                fill="#FC763A"
            />
        </svg>
    );
};

const SiteFooter = () => {
    const { width } = useWindowSize();
    const isMobile = () => width <= theme.mobileBreakpoint;

    const bcProducts = getProductsByCategory("bc");
    const edProducts = getProductsByCategory("ed");

    const userLocation = useSelector((state) => state.appReducer.userLocation);

    return (
        <FooterContainer>
            <Row gutter={30}>
                <Column sm={24} md={24} lg={4} textAlign="left">
                    <Link href="/">
                        <a>
                            <FullLogo />
                        </a>
                    </Link>
                </Column>
                <Column sm={24} md={24} lg={1}></Column>
                <Column
                    sm={24}
                    md={24}
                    lg={5}
                    textAlign="left"
                    paddingTop={isMobile() ? "30px" : ""}>
                    <FooterTitleLink href="/category/erectile">
                        Erectile Dysfunction
                    </FooterTitleLink>
                    {edProducts.map((product) => (
                        <FooterLink href={`/products/${product.slug}`}>
                            {product.name}
                        </FooterLink>
                    ))}
                </Column>
                <Column
                    sm={24}
                    md={24}
                    lg={5}
                    textAlign="left"
                    paddingTop={isMobile() ? "30px" : ""}>
                    <FooterTitleLink href="/category/birth-control">
                        Birth Control
                    </FooterTitleLink>
                    {bcProducts.map((product) => (
                        <FooterLink href={`/products/${product.slug}`}>
                            {product.name}
                        </FooterLink>
                    ))}
                </Column>
                <Column
                    sm={24}
                    md={24}
                    lg={4}
                    paddingTop={isMobile() ? "30px" : ""}
                    textAlign="left">
                    <FooterTitle>Useful Links</FooterTitle>
                    <FooterLink href="/user/sign-up">Sign up</FooterLink>
                    <FooterLink href="/how-it-works">How it works</FooterLink>
                    <FooterLink href="/what-we-carry">What we carry</FooterLink>
                    <FooterLink href="/faq">FAQ</FooterLink>
                    <FooterLink href="/contact-us">Contact Us</FooterLink>
                </Column>
                <Column
                    sm={24}
                    md={24}
                    lg={5}
                    paddingTop={isMobile() ? "30px" : ""}
                    textAlign="left">
                    <FooterTitle>Legal</FooterTitle>

                    {userLocation !== "US" && (
                        <FooterLink href="/privacy-policy-ca">
                            Privacy Policy
                        </FooterLink>
                    )}

                    {userLocation === "US" && (
                        <FooterLink href="/privacy-policy-us">
                            Privacy Policy
                        </FooterLink>
                    )}

                    {userLocation !== "US" && (
                        <FooterLink href="/terms-of-use-ca">
                            Terms of Use
                        </FooterLink>
                    )}
                    {userLocation === "US" && (
                        <FooterLink href="/terms-of-use-us">
                            Terms of Use
                        </FooterLink>
                    )}

                    <a href="/privacy-policy-ca" style={{ display: "none" }}>
                        Privacy Policy Canada
                    </a>
                    <a href="/privacy-policy-us" style={{ display: "none" }}>
                        Privacy Policy US
                    </a>
                    <a href="/terms-of-use-ca" style={{ display: "none" }}>
                        Tems of Use Canada
                    </a>
                    <a href="/terms-of-use-us" style={{ display: "none" }}>
                        Tems of Use US
                    </a>

                    <FooterLink href="/tele-health">
                        Telehealth Informed Consent
                    </FooterLink>
                </Column>
            </Row>
            <Row justify="center">
                <Column sm={24} md={24} lg={24} paddingTop="50px">
                    <RightsText>&#169; All rights reserved</RightsText>
                </Column>
            </Row>
        </FooterContainer>
    );
};
export default SiteFooter;
