import React from "react";
import message from "./message";

function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = React.useState({
        width: undefined,
        height: undefined,
    });

    React.useEffect(() => {
        // only execute all the code below in client side
        if (typeof window !== "undefined") {
            // Handler to call on window resize
            const handleResize = () => {
                // Set window width/height to state
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            };

            // Add event listener
            window.addEventListener("resize", handleResize);

            // Call handler right away so state gets updated with initial window size
            handleResize();

            // Remove event listener on cleanup
            return () => window.removeEventListener("resize", handleResize);
        }
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}

const handleError = (err) => {
    if (err && err.response?.data?.message) {
        return message.create({
            type: "error",
            text: err.response.data.message,
            duration: 10,
        });
    } else if (err) {
        return message.create({ type: "error", text: err, duration: 10 });
    }
    return message.create({ type: "error", text: "Unexpected error" });
};
const handleSuccess = (msg) => {
    return message.create({
        type: "success",
        text: msg,
    });
};
const handleInfo = (msg) => {
    return message.create({
        type: "info",
        text: msg,
        duration: 5,
    });
};

export { useWindowSize, handleError, handleSuccess, handleInfo };
