import React from "react";
import App from "next/app";
import Layout from "../layout/layout";
import AppContextProvider from "../context/appContextProvider";
import { VisitStateProvider } from "../util/state/visit";

import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import rootReducer from "../redux/reducers";

import "/styles/styles.css";

import cookies from "next-cookies";
import { getSessionApi } from "../repository/authRepository";

const store = createStore(rootReducer, applyMiddleware(thunk));

class MyApp extends App {
    static async getInitialProps({ Component, ctx }) {
        const pageProps = Component.getInitialProps
            ? await Component.getInitialProps(ctx)
            : {};

        let poSessionLocation = cookies(ctx).poSessionLocation || undefined;
        let poSessionToken = cookies(ctx).poSessionToken || undefined;
        let sessionData = undefined;

        if (poSessionToken) {
            const apiResponse = await getSessionApi(
                poSessionLocation,
                poSessionToken
            );
            if (apiResponse.status === 200) {
                sessionData = apiResponse.data[0];
            }
        }

        return {
            pageProps: pageProps,
            sessionData: sessionData,
        };
    }

    render() {
        //Information that was returned  from 'getInitialProps' are stored in the props i.e. pageProps
        const { Component, pageProps, sessionData } = this.props;

        return (
            <AppContextProvider>
                <Provider store={store}>
                    <VisitStateProvider>
                        <Layout sessionData={sessionData}>
                            <Component {...pageProps} />
                        </Layout>
                    </VisitStateProvider>
                </Provider>
            </AppContextProvider>
        );
    }
}

export { store };
export default MyApp;
