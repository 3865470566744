import React from "react";
import S from "styled-components";
import theme from "../Theme";

const H1 = S.h1`
	font-weight: ${theme.fontSizes.H1.weight};
	font-style: ${theme.fontSizes.H1.style};
	color: ${(p) => p.color};
	font-family: Poppins;
	line-height: 1.1;
	@media ${theme.breakpoints.xs} {
		font-size: 2.5rem;
	}
	@media ${theme.breakpoints.sm} {
		font-size: 3rem;
	}
	@media ${theme.breakpoints.md} {
		font-size: ${theme.fontSizes.H1.font};
	}
`;

const H2 = S.h2`
    font-weight: ${theme.fontSizes.H2.weight};
    font-style: ${theme.fontSizes.H2.style};
    color: ${(p) => p.color};
    line-height: 1.1;
	font-family: Poppins;
	@media ${theme.breakpoints.xs} {
		font-size: 2rem;
	}
	@media ${theme.breakpoints.sm} {
		font-size: 2.5rem;
	}
	@media ${theme.breakpoints.md} {
		font-size: ${theme.fontSizes.H2.font};
	}
`;

const H3 = S.h3`
    font-weight: ${theme.fontSizes.H3.weight};
    font-style: ${theme.fontSizes.H3.style};
    color: ${(p) => p.color};
	font-family: Poppins;
	@media ${theme.breakpoints.xs} {
		font-size: 1.8rem;
	}
	@media ${theme.breakpoints.sm} {
		font-size: 2rem;
	}
	@media ${theme.breakpoints.md} {
		font-size: ${theme.fontSizes.H3.font};
	}
`;
const H4 = S.h4`
    font-size: ${theme.fontSizes.H4.font};
    font-weight: ${theme.fontSizes.H4.weight};
    font-style: ${theme.fontSizes.H4.style};
    color: ${(p) => p.color};
    line-height: 1;
	font-family: Poppins;
`;
const H5 = S.h5`
    font-size: ${theme.fontSizes.H5.font};
    font-weight: ${theme.fontSizes.H5.weight};
    font-style: ${theme.fontSizes.H5.style};
    color: ${(p) => p.color};
	font-family: Poppins;
`;
const H6 = S.h6`
    font-size: ${theme.fontSizes.H6.font};
    font-weight: ${theme.fontSizes.H6.weight};
    font-style: ${theme.fontSizes.H6.style};
    color: ${(p) => p.color};
	font-family: Poppins;
`;
const Body1 = S.p`
    font-size: ${theme.fontSizes.Body1.font};
    font-weight: ${theme.fontSizes.Body1.weight};
    font-style: ${theme.fontSizes.Body1.style};
    color: ${(p) => p.color};
	font-family: Poppins;
`;
const Body2 = S.p`
    font-size: ${theme.fontSizes.Body2.font};
    font-weight: ${theme.fontSizes.Body2.weight};
    font-style: ${theme.fontSizes.Body2.style};
    color: ${(p) => p.color};
	font-family: Poppins;
`;
const Body3 = S.p`
    font-size: ${theme.fontSizes.Body3.font};
    font-weight: ${theme.fontSizes.Body3.weight};
    font-style: ${theme.fontSizes.Body3.style};
    color: ${(p) => p.color};
	font-family: Poppins;
`;
const Body4 = S.p`
    font-size: ${theme.fontSizes.Body4.font};
    font-weight: ${theme.fontSizes.Body4.weight};
    font-style: ${theme.fontSizes.Body4.style};
    color: ${(p) => p.color};
	font-family: Poppins;
`;
const Body5 = S.p`
    font-size: ${theme.fontSizes.Body5.font};
    font-weight: ${theme.fontSizes.Body5.weight};
    font-style: ${theme.fontSizes.Body5.style};
    color: ${(p) => p.color};
	font-family: Poppins;
`;
const Body6 = S.p`
    font-size: ${theme.fontSizes.Body6.font};
    font-weight: ${theme.fontSizes.Body6.weight};
    font-style: ${theme.fontSizes.Body6.style};
    color: ${(p) => p.color};
    text-align: ${(p) => p.align};
	font-family: Poppins;
    line-height: 1.5rem;
    a{
        color: ${theme.colors.primary800};
    }
`;
const Body7 = S.p`
    font-size: ${theme.fontSizes.Body7.font};
    font-weight: ${theme.fontSizes.Body7.weight};
    font-style: ${theme.fontSizes.Body7.style};
    color: ${(p) => p.color};
    text-align: ${(p) => p.align};
	font-family: Poppins;
`;
const Body8 = S.p`
    font-size: ${theme.fontSizes.Body8.font};
    font-weight: ${theme.fontSizes.Body8.weight};
    font-style: ${theme.fontSizes.Body8.style};
    color: ${(p) => p.color};
	font-family: Poppins;
`;
const Body9 = S.p`
    font-size: ${theme.fontSizes.Body9.font};
    font-weight: ${theme.fontSizes.Body9.weight};
    font-style: ${theme.fontSizes.Body9.style};
    color: ${(p) => p.color};
    line-height: 1.5rem;
	font-family: Poppins;
`;
const Body10 = S.p`
    font-size: ${theme.fontSizes.Body10.font};
    font-weight: ${theme.fontSizes.Body10.weight};
    font-style: ${theme.fontSizes.Body10.style};
    color: ${(p) => p.color};
`;

const Body11 = S.p`
    font-size: ${theme.fontSizes.Body11.font};
    font-weight: ${theme.fontSizes.Body11.weight};
    font-style: ${theme.fontSizes.Body11.style};
    color: ${(p) => p.color};
	font-family: Poppins;
	
`;

const Body12 = S.p`
    font-size: ${theme.fontSizes.Body12.font};
    font-weight: ${theme.fontSizes.Body12.weight};
    font-style: ${theme.fontSizes.Body12.style};
    color: ${(p) => p.color};
`;

const Body13 = S.p`
    font-size: ${theme.fontSizes.Body13.font};
    font-weight: ${theme.fontSizes.Body13.weight};
    font-style: ${theme.fontSizes.Body13.style};
    color: ${(p) => p.color};
	font-family: Poppins;
`;

const Body14 = S.p`
    font-size: ${theme.fontSizes.Body14.font};
    font-weight: ${theme.fontSizes.Body14.weight};
    font-style: ${theme.fontSizes.Body14.style};
    color: ${(p) => p.color};
	font-family: Poppins;
`;

const Body15 = S.p`
    font-size: ${theme.fontSizes.Body15.font};
    font-weight: ${theme.fontSizes.Body15.weight};
    font-style: ${theme.fontSizes.Body15.style};
    color: ${(p) => p.color};
	font-family: Poppins;
`;
const Body16 = S.p`
    font-size: ${theme.fontSizes.Body16.font};
    font-weight: ${theme.fontSizes.Body16.weight};
    font-style: ${theme.fontSizes.Body16.style};
    color: ${(p) => p.color};
	font-family: Poppins;
`;

const TYPES = {
    h1: {
        id: "h1",
        component: H1,
    },
    h2: {
        id: "h2",
        component: H2,
    },
    h3: {
        id: "h3",
        component: H3,
    },
    h4: {
        id: "h4",
        component: H4,
    },
    h5: {
        id: "h5",
        component: H5,
    },
    h6: {
        id: "h6",
        component: H6,
    },
    body1: {
        id: "body1",
        component: Body1,
    },
    body2: {
        id: "body2",
        component: Body2,
    },
    body3: {
        id: "body3",
        component: Body3,
    },
    body4: {
        id: "body4",
        component: Body4,
    },
    body5: {
        id: "body5",
        component: Body5,
    },
    body6: {
        id: "body6",
        component: Body6,
    },
    body7: {
        id: "body7",
        component: Body7,
    },
    body8: {
        id: "body8",
        component: Body8,
    },
    body9: {
        id: "body9",
        component: Body9,
    },
    body10: {
        id: "body10",
        component: Body10,
    },

    body11: {
        id: "body11",
        component: Body11,
    },

    body12: {
        id: "body12",
        component: Body12,
    },

    body13: {
        id: "body13",
        component: Body13,
    },

    body14: {
        id: "body14",
        component: Body14,
    },

    body15: {
        id: "body15",
        component: Body15,
    },

    body16: {
        id: "body16",
        component: Body16,
    },
};

interface Props {
    type: any;
    align?: any;
    children?: any;
    color?: any;
    mb?: any;
    mr?: any;
    fontWeight?: any;
    onClick?: any;
    pointer: boolean;
}

const Text = ({
    type,
    align,
    children,
    color,
    mb,
    mr,
    fontWeight,
    onClick,
    pointer,
}: Props) => {
    return React.createElement(TYPES[type].component, {
        onClick,
        style: {
            textAlign: align,
            color: color,
            marginBottom: mb,
            marginRight: mr,
            fontWeight,
            cursor: pointer ? "pointer" : "default",
        },
        children,
    });
};

Text.defaultProps = {
    color: theme.colors.text900,
    pointer: false,
};

export default Text;
