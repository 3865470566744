import React, { useState } from "react";
import S from "styled-components";
import theme from "../Theme";
import { useWindowSize } from "../../util";
import Link from "next/link";

const Types = {
    primary: {
        background: "#FBEAE6",
        width: "300px",
        mobileWidth: "100%",
        border: "1px solid #FBEAE6;",
        borderRadius: "10px;",
        contentColor: "#FC763A",
        weight: "600",
        fontSize: "16px",
        hoverBackground: "#FECEBA",
        hoverContentColor: "#FC763A",
    },
    secondary: {
        background: "#C5C7F5",
        width: "300px",
        mobileWidth: "100%",
        border: "1px solid #C5C7F5;",
        borderRadius: "10px;",
        contentColor: "#2F37DE",
        weight: "600",
        fontSize: "16px",
        hoverBackground: "#9BA4F8",
        hoverContentColor: "#2F37DE",
    },
};

const Container = S.a`
	${(p) => (p.ml ? `margin-left: ${p.ml};` : ``)}
    background-color: ${(p) => p.background};
    padding: 12px 20px;
    width: ${(p) => p.width};
	height: 200px;
    border: ${(p) => p.border};
    border-radius: ${(p) => p.borderRadius};
    box-shadow: ${(p) => p.boxShadow};
    max-height: auto;
	display:flex;
	justify-content: center; 
	align-items: center; 
    margin-top: ${(p) => p.marginTop};
    margin-bottom: ${(p) => p.marginTop};    
    cursor: pointer;
    position: relative;
`;

const Title = S.div`
    overflow: hidden;
    text-overflow:ellipsis;
    color: ${(p) => p.color};
    font-weight: ${(p) => p.weight};
    font-size: ${(p) => p.size};
	display:flex;
	align-items:center;
    width: auto;
    position: absolute;
	left: 15px;
    bottom: 20px;
    max-width: 32%;
    text-align: left;
`;
const Icon = S.img`
	width: 30px;
	bottom: 18px;
	position: absolute;
	// margin-left: 25px;
`;
const Media = S.img`
	width: 73%;
	position: absolute;
	right: 12px;
    top: -25px;
    max-width: 230px;
}
`;

interface Props {
    type: string;
    title: string;
    mt?: string;
    mb?: string;
    ml?: string;
    image: string;
    href: string;
}

const ProductCard = ({ title, mt, mb, type, image, href, ml }: Props) => {
    const { width } = useWindowSize();
    const isMobile = () => width <= theme.mobileBreakpoint;
    const [over, setOver] = useState(false);

    let backgroundColor = "#FC763A";
    let contentColor = "#fff";

    if (over) {
        backgroundColor = Types[type].hoverBackground;
        contentColor = Types[type].hoverContentColor;
    } else {
        backgroundColor = Types[type].background;
        contentColor = Types[type].contentColor;
    }

    return (
        <Link href={href}>
            <Container
                ml={ml}
                onMouseOver={() => setOver(true)}
                onMouseOut={() => setOver(false)}
                marginTop={mt}
                marginBottom={mb}
                width={isMobile() ? Types[type].mobileWidth : Types[type].width}
                background={backgroundColor}
                border={Types[type].border}
                borderRadius={Types[type].borderRadius}
                boxShadow={Types[type].boxShadow}
                href={href}>
                <Media src={image} />
                <Title
                    weight={Types[type].weight}
                    color={contentColor}
                    size={Types[type].fontSize}>
                    {title}
                </Title>
                <Icon
                    src={
                        type === "primary"
                            ? "/images/productitemarroworange.svg"
                            : "/images/productitemarrowblue.svg"
                    }
                />
            </Container>
        </Link>
    );
};

ProductCard.defaultProps = {
    title: "Example Button",
    type: "primary",
};

export default ProductCard;
