import React from "react";
import { Row } from "antd";
import "antd/dist/antd.css";

const CustomRow = ({ className, justify, direction, children }) => {
    return (
        <>
            <Row
                className={className}
                style={{
                    justifyContent: justify,
                    flexDirection: direction,
                }}>
                {children}
            </Row>
        </>
    );
};

CustomRow.defaultProps = {
    textAlign: "center",
};

export default CustomRow;
