import { message } from "antd";

interface Props {
    type: "success" | "error" | "info" | "warning" | "warn" | "loading";
    text: string;
    duration?: number;
}

const create = ({ type, text, duration }: Props) => {
    return message[type](text, duration);
};

create.defaultProps = {
    duration: 3,
};

export default {
    create,
};
