import React, { useState } from "react";
import S from "styled-components";
import theme from "../Theme";
import Link from "next/link";
import { ProductMock } from "../../util/types";

const Types = {
    primary: {
        background: theme.colors.white,
        border: "1px solid #FECEBA;",
        borderRadius: "10px;",
        contentColor: "#FC763A",
        weight: "600",
        fontSize: "16px",
        hoverBackground: "#FBEAE6",
        hoverContentColor: "#FC763A",
        Icon: "/images/productitemarroworange.svg",
    },
    secondary: {
        background: theme.colors.white,
        border: "1px solid #C5C7F5;",
        borderRadius: "10px;",
        contentColor: "#2F37DE",
        weight: "600",
        fontSize: "16px",
        hoverBackground: "#E7E8FB",
        hoverContentColor: "#2F37DE",
        Icon: "/images/productitemarrowblue.svg",
    },
};

const Container = S.a`
    background-color: ${(p) => p.background};
    padding: 12px 20px;
    min-width: ${(p) => p.minWidth};
    border: ${(p) => p.border};
    border-radius: ${(p) => p.borderRadius};
    box-shadow: ${(p) => p.boxShadow};
    max-height: auto;
	height: 50px;
	display:flex;
	justify-content: center; 
	align-items: center; 
    margin-top: ${(p) => p.marginTop};
    margin-bottom: ${(p) => p.marginTop};    
    cursor: pointer;
    position: relative;
    width: ${(p) => p.width};
`;

const Title = S.div`
    color: ${(p) => p.color};
    font-weight: ${(p) => p.weight};
    font-size: ${(p) => p.size};
	display:flex;
	align-items:center;
	flex-grow: 1;
    // width: 90%;
    // position: absolute;
    // right: ${(p) => p.right};
`;
const Icon = S.img`
	width: 20px;
    position: absolute;
    right: 12px;
`;

interface Props {
    type: string;
    title: string;
    mt?: string;
    mb?: string;
    rightIcon?: any;
    rightIconOnHover?: any;
    fullWidth?: boolean;
    onClick?: any;
    href: string;
    product: ProductMock;
}

const ProductButton = ({
    title,
    mt,
    mb,
    type,
    rightIcon,
    fullWidth,
    product,
    rightIconOnHover,
}: Props) => {
    const [over, setOver] = useState(false);

    let backgroundColor = "#FC763A";
    let contentColor = "#fff";
    let buttonIcon = "";

    if (over) {
        backgroundColor = Types[type].hoverBackground;
        contentColor = Types[type].hoverContentColor;
        buttonIcon = rightIconOnHover;
    } else {
        backgroundColor = Types[type].background;
        contentColor = Types[type].contentColor;
        buttonIcon = rightIcon;
    }

    if (product != null) {
        return (
            <Link href="/products/[slug]" as={`/products/${product.slug}`}>
                <Container
                    onMouseOver={() => setOver(true)}
                    onMouseOut={() => setOver(false)}
                    marginTop={mt}
                    marginBottom={mb}
                    minWidth={Types[type].minWidth}
                    background={backgroundColor}
                    border={Types[type].border}
                    borderRadius={Types[type].borderRadius}
                    boxShadow={Types[type].boxShadow}
                    width={fullWidth ? "100%" : ""}
                    href={`/products/${product.slug}`}>
                    <Title
                        weight={Types[type].weight}
                        color={contentColor}
                        size={Types[type].fontSize}
                        right={fullWidth ? "0px" : "15px"}>
                        {title}
                    </Title>
                    <Icon src={Types[type].Icon} />
                </Container>
            </Link>
        );
    } else {
        return <></>;
    }
};

ProductButton.defaultProps = {
    title: "Example Button",
    type: "primary",
};

export default ProductButton;
