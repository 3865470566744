import React from "react";
import "antd/dist/antd.css";
import S from "styled-components";

const StyledSpacer = S.div`

`;
interface Props {
    height: string;
}

const Spacer = ({ height }: Props) => {
    return (
        <>
            <StyledSpacer
                style={{
                    height: height,
                }}
            />
        </>
    );
};

Spacer.defaultProps = {};

export default Spacer;
