import apiCall from "../util/api";
import emptyStringToNull from "../util/emptyStringToNull";

const PREFIX = "auth";

export const loginApi = async (email, password) => {
    const apiRequestHeader = {};
    const apiRequestData = emptyStringToNull({
        customerEmail: email,
        customerPassword: password,
    });
    const apiResponse = await apiCall(
        `${PREFIX}/login`,
        "POST",
        apiRequestHeader,
        apiRequestData,
        {}
    );
    return apiResponse;
};

export const getSessionApi = async (userLocation, token) => {
    const apiRequestHeader = { token };
    const apiRequestData = {};

    const apiResponse = await apiCall(
        `${PREFIX}/session`,
        "GET",
        apiRequestHeader,
        apiRequestData,
        {},
        userLocation
    );
    return apiResponse;
};

export const logoutApi = async (token, userLocation) => {
    const apiRequestHeader = { token };
    const apiRequestData = {};
    const apiResponse = await apiCall(
        `${PREFIX}/logout`,
        "GET",
        apiRequestHeader,
        apiRequestData,
        {},
        userLocation
    );
    return apiResponse;
};

export const changePasswordApi = async (token, oldPassword, newPassword) => {
    const apiRequestHeader = { token };
    const apiRequestData = emptyStringToNull({
        customerOldPassword: oldPassword,
        customerPassword: newPassword,
    });
    const apiResponse = await apiCall(
        `${PREFIX}/change_password`,
        "POST",
        apiRequestHeader,
        apiRequestData,
        {}
    );
    return apiResponse;
};

export const signUpApi = async (
    firstName,
    lastName,
    email,
    countryCode,
    phone,
    password
) => {
    const apiRequestHeader = {};
    const apiRequestData = emptyStringToNull({
        customerFirstName: firstName,
        customerLastName: lastName,
        customerEmail: email,
        customerCountryCode: countryCode,
        customerPhone: phone,
        customerPassword: password,
    });
    const apiResponse = await apiCall(
        `${PREFIX}/register`,
        "POST",
        apiRequestHeader,
        apiRequestData,
        {}
    );
    return apiResponse;
};

export const forgotPasswordApi = async (email) => {
    const apiRequestHeader = {};
    const apiRequestData = emptyStringToNull({
        customerEmail: email,
    });
    return await apiCall(
        `${PREFIX}/reset_password/request`,
        "POST",
        apiRequestHeader,
        apiRequestData,
        {}
    );
};

export const confirmPasswordApi = async (token, password) => {
    const apiRequestHeader = {};
    const apiRequestData = emptyStringToNull({
        resetPasswordToken: token,
        customerPassword: password,
    });
    return await apiCall(
        `${PREFIX}/reset_password/confirm`,
        "POST",
        apiRequestHeader,
        apiRequestData,
        {}
    );
};

export default {};
